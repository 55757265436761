/* eslint-disable */

import { notification } from "ant-design-vue";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { h } from "vue";
import TYPE from "../constants/constant";

let errorNotificationId = "";
let inforNotificationId = "";

function axiosErrorHandler(error) {
  try {
    const axiosError = error;
    if (axiosError.response) {
      if (axiosError.response.data && axiosError.response.data.error) {
        return {
          status: axiosError.response.data.error.statusCode,
          message: axiosError.response.data.error.message,
        };
      } else {
        return {
          status: axiosError.response.status,
          message:
            "Oops! It looks like something didn't work. Please try again after a few moments.",
        };
      }
    } else {
      return {
        status: 500,
        message: "Oops! It looks like something didn't work. Please try again after a few moments.",
      };
    }
  } catch (error) {
    return {
      status: 500,
      message: "Oops! It looks like something didn't work. Please try again after a few moments.",
    };
  }
}

function getErrorId() {
  return `error-${new Date().getTime()}`;
}

function closeNotification(id = '') {
  notification.close(id);
  if (inforNotificationId) {
    inforNotificationId = "";
    notification.close(inforNotificationId);
  }
  if (errorNotificationId) {
    errorNotificationId = "";
    notification.close(errorNotificationId);
  }
}
function getTime(timestamp) {
  return dayjs(timestamp).format("hh:mm:ss A");
}
function getInfoId() {
  return `info-${new Date().getTime()}`;
}
function notificationError(message, description,  {duration = null} = {}) {
  if (errorNotificationId) {
    closeNotification(errorNotificationId);
  }
  errorNotificationId = getErrorId();
  notification.error({
    key: errorNotificationId,
    placement: "topRight",
    duration,
    message: TYPE.ERROR_CODE_MESSAGE[message] ? TYPE.ERROR_CODE_MESSAGE[message] : message,
    description,
    class: "error",
  });

  return errorNotificationId;
}
function notificationInfo(message, description) {
  notification.info({
    placement: "topRight",
    duration: 4,
    message: message,
    description: description,
  });
}

function showNotification(message, description, {duration = null} = {}) {
  if (inforNotificationId) {
    closeNotification(inforNotificationId);
  } else {
    inforNotificationId = getInfoId();
    notification.info({
      key: inforNotificationId,
      placement: "topRight",
      duration,
      message,
      description,
      icon: () =>
        h(LoadingOutlined, {
          style: "color: #108EE9",
        }),
    });
  }

  return inforNotificationId;
}

const Operations = {
  axiosErrorHandler,
  notificationError,
  notificationInfo,
  showNotification,
  closeNotification,
  getTime,
};

export default Operations;
