const MOBILE_SIGNUP = 'clients/send/otp/sms';
const MOBILE_OTP = 'clients/validate/otp/mobile';
const PAN_DETAILS = 'clients/stages/pan';
const ACCOUNT_VALIDATION = 'clients/validate/account';
const PAN_UPLOAD = 'clients/stages/pan/ocr';
const EMAIL_SIGNUP = 'clients/send/otp/email';
const EMAIL_OTP = '/clients/validate/otp/email';
const CONFIRM_PAN_DETAILS = '/clients/stages/pan/confirm';
const UPLOAD_ADDRESS_PROOF = '/clients/stages/address/ocr';
const CONFIRM_ADDRESS_PROOF = '/clients/stages/address/confirm';
const PERSONAL_DETAILS = '/clients/stages/profile/personal';
const DECLARATIONS = '/clients/stages/profile/declarations';
const ADD_NOMINEE = '/clients/v2/stages/nominee';
const UPLOAD_DOCUMENT = '/clients/documents/upload';
const ACTIVATE_FNO = '/clients/stages/activate/fno';
const ADD_BANK_MANUALLY = '/clients/stages/banking/manual';
const BANK_ACCOUNT_VERIFICATION = '/clients/stages/banking';
const CHEQUE_UPLOAD = '/clients/stages/banking/cheque/ocr';
const SELFIE_VERIFICATION = '/clients/stages/selfie/verify';
const SIGNATURE_VERIFICATION = '/clients/stages/signature';
const GET_STAGES = '/clients/stages';
const GOOGLE_AUTHENTICATION = '/clients/authentication/oauth/google';
const AADHAAR_ESIGN = '/clients/stages/esign/request';
const FETCH_DASHBOARD_STATISTICS = '/clients/dashboard/statistics';
const FETCH_DASHBOARD_KYCS = '/clients/dashboard/kycs';
const ACCEPT_KYC = '/clients/dashboard/kycs/:kycId/accept';
const FETCH_KYC_APPLICATION = '/clients/dashboard/kycs/:kycId';
const ACCEPT_KYC_STAGE = '/clients/dashboard/kycs/:kycId/stages/accept';
const REJECT_KYC_STAGE = '/clients/dashboard/kycs/:kycId/stages/reject';
const ADMIN_LOGIN = '/users/login';
const ADMIN_LOGOUT = '/users/logout';
const BOW_USER_ACTIVATED = '/clients/dashboard/kycs/:kycId/bow/activated';
const BOW_USER_LOGIN = '/users/bow/login';
const BOW_VERIFY_OTP = '/users/bow/otp/verify';
const BOW_RESEND_OTP = '/users/bow/otp/resend';
const AADHAAR_ESIGN_STATUS = '/clients/stages/esign/status';
const VALIDATE_SESSION = '/sessions/validate';
const IPV_VERIFICATION = '/clients/stages/ipv/verify';
const KRA_SYNC = '/clients/:kycId/sync/kra';
const CHEQUE_CONFIRM = '/clients/stages/cheque/confirm';
const CLOSE_ACCOUNT = '/clients/dashboard/kycs/:kycId/close';

const ONBOARDING = {
  MOBILE_SIGNUP,
  MOBILE_OTP,
  PAN_DETAILS,
  ACCOUNT_VALIDATION,
  PAN_UPLOAD,
  EMAIL_SIGNUP,
  EMAIL_OTP,
  CONFIRM_PAN_DETAILS,
  UPLOAD_ADDRESS_PROOF,
  CONFIRM_ADDRESS_PROOF,
  PERSONAL_DETAILS,
  DECLARATIONS,
  ADD_NOMINEE,
  UPLOAD_DOCUMENT,
  ACTIVATE_FNO,
  ADD_BANK_MANUALLY,
  BANK_ACCOUNT_VERIFICATION,
  CHEQUE_UPLOAD,
  SELFIE_VERIFICATION,
  SIGNATURE_VERIFICATION,
  GET_STAGES,
  GOOGLE_AUTHENTICATION,
  AADHAAR_ESIGN,
  AADHAAR_ESIGN_STATUS,
  VALIDATE_SESSION,
  IPV_VERIFICATION,
  CHEQUE_CONFIRM,
};

const ADMIN_DASHBOARD = {
  FETCH_DASHBOARD_STATISTICS,
  FETCH_DASHBOARD_KYCS,
  ACCEPT_KYC,
  FETCH_KYC_APPLICATION,
  ACCEPT_KYC_STAGE,
  REJECT_KYC_STAGE,
  ADMIN_LOGIN,
  ADMIN_LOGOUT,
  BOW_USER_ACTIVATED,
  BOW_USER_LOGIN,
  BOW_VERIFY_OTP,
  BOW_RESEND_OTP,
  KRA_SYNC,
  CLOSE_ACCOUNT,
};

const ROUTES = {
  ONBOARDING,
  ADMIN_DASHBOARD,
  ADMIN_LOGIN,
};

export default ROUTES;
