/* eslint-disable */
import axios from 'axios';
import Utils from '../../../utils';
import { MOCK_API_BASE_URL, API_BASE_URL } from '../../../constants';

const MockAxios = axios.create({
  baseURL: MOCK_API_BASE_URL,
});

MockAxios.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Prefer = 'code=200';
    return config;
  },
  (error) => Promise.reject(error),
);

MockAxios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

const LiveAxios = axios.create({
  baseURL: API_BASE_URL,
  // validateStatus: status => {
  //   return status === 200
  // }
});

LiveAxios.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Prefer = 'code=200';
    return config;
  },
  (error) => Promise.reject(error),
);

LiveAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const parsedError = error.toJSON();
    if (parsedError.status == 401) {
      Utils.sessionExpired(error);
      // Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export { LiveAxios, MockAxios };
