<template>
  <a-layout class='onboarding-main-layout-wrapper'>
    <a-layout-header class='app-layout-common-header'>
      <CommonHeader/>
    </a-layout-header>
    <a-layout-content class='app-layout-content-wrapper'>
      <router-view />
    </a-layout-content>
  </a-layout>
</template>
<script>

import { useRouter, useRoute } from 'vue-router';
import {
  computed,
  // ref,
  watch,
  onMounted,
} from 'vue';
import { useStore } from 'vuex';
import CommonHeader from './features/common/components/commonHeader.vue';
import services from './services/apis';

export default {
  name: 'App',
  components: {
    CommonHeader,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const validateSession = () => {
      services
        .validateSession()
        .then(() => {
          if (window.location.pathname.includes('/onboarding')) {
            store.dispatch('onboardingStore/updateAppHeader', 'CLIENTUSER');
            store.dispatch('onboardingStore/updateClientLogin', true);
          }
          if (window.location.pathname.includes('/admin')) {
            store.dispatch('onboardingStore/updateAppHeader', 'ADMINUSER');
            store.dispatch('onboardingStore/updateAdminLogin', true);
          }
        })
        .catch(() => {
        });
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      validateSession();
      if (window.location.pathname === '/') {
        router.push('/onboarding');
      }
    });

    watch(() => {
      const path = computed(() => route.path).value;
      if (path === '/onboarding') {
        store.dispatch('onboardingStore/updateAppHeader', 'CLIENTUSER');
      }
      if (path === '/admin/login') {
        store.dispatch('onboardingStore/updateAppHeader', 'ADMINUSER');
      }
    });

    return {};
  },
};
</script>

<style lang='scss'>
@import './theme.scss';
@font-face {
  font-family: 'Pangram ExtraLight';
  src: local('Pangram ExtraLight'),
    url('./fonts/Pangram/Pangram-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Pangram Light';
  src: local('Pangram Light'),
    url('./fonts/Pangram/Pangram-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Pangram Regular';
  src: local('Pangram Regular'),
    url('./fonts/Pangram/Pangram-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Pangram Medium';
  src: local('Pangram Medium'),
    url('./fonts/Pangram/Pangram-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Pangram Bold';
  src: local('Pangram Bold'),
    url('./fonts/Pangram/Pangram-Bold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Pangram ExtraBold';
  src: local('Pangram ExtraBold'),
    url('./fonts/Pangram/Pangram-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Pangram Black';
  src: local('Pangram Black'),
    url('./fonts/Pangram/Pangram-Black.ttf') format('truetype');
  font-weight: 900;
}
.onboarding-main-layout-wrapper {
  max-width: 1440px;
  margin: 0 auto;
}
.app-layout-common-header {
  background: $white-background;
  height: 100px;
  position: fixed;
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  // padding: 0 110px;
  box-shadow: 0 -3px 15px 0 rgb(0 0 0 / 25%);
}
.app-layout-content-wrapper {
  margin-top: 100px;
  height: auto;
  padding: 10px;
  background-color: $white-background;
}
// .app-layout-admin-content-wrapper{
//    height: auto;
//   padding: 10px;
//   background-color: $white-background;
// }
.app-layout-common-footer {
  background: $footer-background;
  padding: 24px 110px;
}
// .input-field .ant-input {
//     background: #ffffff !important;
//     border: 1px solid #79a89b !important;
//     border-radius: 2px !important;
//     height: 44px ;
// }
</style>
