import { createApp } from 'vue';
import 'ant-design-vue/dist/antd.css';
import Antd from 'ant-design-vue';
import vue3GoogleLogin from 'vue3-google-login';
import { GOOGLE_CLIENT_ID } from './constants';
import App from './App.vue';
import router from './features/onboarding/router/index';
import store from './store';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(Antd);
app.use(vue3GoogleLogin, {
  clientId: GOOGLE_CLIENT_ID,
});
app.mount('#app');
