const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const MOCK_API_BASE_URL = process.env.VUE_APP_MOCK_API_BASE_URL;
const GOOGLE_CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID;
const ESIGN_LOGO = process.env.VUE_APP_ESIGN_LOGO;
const APP_ENV = process.env.VUE_APP_NODE_ENV;
const LOG_ROCKET_SECRET = process.env.VUE_APP_LOG_ROCKET_SECRET;

export {
  API_BASE_URL,
  MOCK_API_BASE_URL,
  GOOGLE_CLIENT_ID,
  ESIGN_LOGO,
  APP_ENV,
  LOG_ROCKET_SECRET,
};
