import { createStore } from 'vuex';
import onboardingStore from './features/onboarding/stores/onboardingStore';
import getLogRocket from './services/logrocket';

const logrocket = getLogRocket();
const plugins = [];

if (logrocket.isAllowed()) {
  const logrocketPlugin = logrocket.vuexPlugin();
  plugins.push(logrocketPlugin);
}

export default createStore({
  modules: {
    onboardingStore,
  },
  plugins,
});
