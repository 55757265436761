import { LiveAxios, MockAxios } from './axios';
import ROUTES from './router';

function replaceKycId(kycId, url) {
  const newURL = url.replace(/:kycId/g, kycId);
  return newURL;
}

export default {
  mobileSignUp: async (params) => {
    const route = ROUTES.MOBILE_SIGNUP;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  mobileOtp: async (params) => {
    const route = ROUTES.MOBILE_OTP;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  emailSignUp: async (params) => {
    const route = ROUTES.EMAIL_SIGNUP;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  emailOtp: async (params) => {
    const route = ROUTES.EMAIL_OTP;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  accountValidation: async (params) => {
    const route = ROUTES.VALIDATE_ACCOUNT;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  panAuthentication: async (params) => {
    const route = ROUTES.AUTHENTICATION_PAN;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  panUpload: async (params) => {
    const route = ROUTES.UPLOAD_PAN;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  confirmPanDetails: async (params) => {
    const route = ROUTES.CONFIRM_UPLOAD_PAN;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  uploadAddressProof: async (params) => {
    const route = ROUTES.UPLOAD_ADDRESS_PROOF;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  confirmUploadedAddressProof: async (params) => {
    const route = ROUTES.CONFIRM_UPLOADED_ADDRESS_PROOF;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  addPersonalDetails: async (params) => {
    const route = ROUTES.ADD_PERSONAL_DETAIL;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  addDeclarations: async (params) => {
    const route = ROUTES.DECLARATIONS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  addNominee: async (params) => {
    const route = ROUTES.ADD_NOMINEE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  uploadDocument: async (params) => {
    const route = ROUTES.UPLOAD_DOCUMENT;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  activateFno: async (params) => {
    const route = ROUTES.ACTIVATE_FNO;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  addBankManually: async (params) => {
    const route = ROUTES.ADD_BANK_MANUALLY;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  bankAccountVerificationStatus: async (params) => {
    const route = ROUTES.BANK_ACCOUNT_VERIFICATION_STATUS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  chequeUpload: async (params) => {
    const route = ROUTES.CHEQUE_UPLOAD;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  selfieVerification: async (params) => {
    const route = ROUTES.SELFIE_VERIFICATION;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  signatureVerification: async (params) => {
    const route = ROUTES.SIGNATURE_VERIFICATION;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  getStages: async (params) => {
    const route = ROUTES.GET_STAGES;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  googleAuthentication: async (params) => {
    const route = ROUTES.GOOGLE_AUTHENTICATION;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  aadhaarEsign: async (params) => {
    const route = ROUTES.AADHAAR_ESIGN;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  fetchDashboardStatistics: async (params) => {
    const route = ROUTES.FETCH_DASHBOARD_STATISTICS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params,
    });
  },
  fetchDashboardKycs: async (params) => {
    const route = ROUTES.FETCH_DASHBOARD_KYCS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params,
    });
  },
  acceptKyc: async (params) => {
    const route = ROUTES.ACCEPT_KYC;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceKycId(params.id, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceKycId(params.id, route.URL),
      data: params.data,
    });
  },
  fetchKycApplication: async (params) => {
    const route = ROUTES.FETCH_KYC_APPLICATION;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceKycId(params.id, route.URL),
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceKycId(params.id, route.URL),
    });
  },
  acceptKycStage: async (params) => {
    const route = ROUTES.ACCEPT_KYC_STAGE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceKycId(params.id, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceKycId(params.id, route.URL),
      data: params.data,
    });
  },
  rejectKycStage: async (params) => {
    const route = ROUTES.REJECT_KYC_STAGE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceKycId(params.id, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceKycId(params.id, route.URL),
      data: params.data,
    });
  },
  adminLogin: async (params) => {
    const route = ROUTES.ADMIN_LOGIN;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  adminLogout: async (params) => {
    const route = ROUTES.ADMIN_LOGOUT;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  bowUserActivated: async (params) => {
    const route = ROUTES.BOW_USER_ACTIVATED;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceKycId(params.id, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceKycId(params.id, route.URL),
      data: params.data,
    });
  },
  bowUserLogin: async (params) => {
    const route = ROUTES.BOW_USER_LOGIN;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  bowVerifyOtp: async (params) => {
    const route = ROUTES.BOW_VERIFY_OTP;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  bowResendOtp: async (params) => {
    const route = ROUTES.BOW_RESEND_OTP;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  aadhaarEsignStatus: async (params) => {
    const route = ROUTES.AADHAAR_ESIGN_STATUS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  validateSession: async (params) => {
    const route = ROUTES.VALIDATE_SESSION;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params,
    });
  },
  uploadCheque: async (params) => {
    const route = ROUTES.UPLOAD_CHEQUE;
    return MockAxios({
      method: route.METHOD,
      url: '/users/stages/banking/cheque',
      data: params,
    });
  },
  requestBankAccountVerify: async (params) => {
    const route = ROUTES.REQUEST_BANK_ACCOUNT_VERIFICATION;
    return MockAxios({
      method: route.METHOD,
      url: '/users/stages/banking',
      data: params,
    });
  },
  uploadSelfie: async (params) => {
    const route = ROUTES.UPLOAD_SELFIE;
    return MockAxios({
      method: route.METHOD,
      url: '/users/stages/selfie',
      data: params,
    });
  },
  uploadSignature: async (params) => {
    const route = ROUTES.ADD_SIGNATURE;
    return MockAxios({
      method: route.METHOD,
      url: '/users/stages/signature',
      data: params,
    });
  },
  verifyIpv: async (params) => {
    const route = ROUTES.IPV_VERIFICATION;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }

    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  syncToKra: async (params) => {
    const route = ROUTES.KRA_SYNC;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceKycId(params.id, route.URL),
        data: params,
      });
    }

    return MockAxios({
      method: route.METHOD,
      url: replaceKycId(params.id, route.URL),
      data: params,
    });
  },
  confirmCheque: async () => {
    const route = ROUTES.CHEQUE_CONFIRM;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
      });
    }

    return MockAxios({
      method: route.METHOD,
      url: route.URL,
    });
  },
  closeAccount: async (params) => {
    const route = ROUTES.CLOSE_ACCOUNT;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceKycId(params.id, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceKycId(params.id, route.URL),
      data: params.data,
    });
  },
};
