const STAGE_TYPE = {
  VALIDATE_MOBILE: 'VALIDATE_MOBILE',
  VALIDATE_EMAIL: 'VALIDATE_EMAIL',
  MOBILE_ACCOUNT_VALIDATION: 'MOBILE_ACCOUNT_VALIDATION',
  EMAIL_ACCOUNT_VALIDATION: 'EMAIL_ACCOUNT_VALIDATION',
  PAN_VALIDATION: 'PAN_VALIDATION',
  PAN_CONFIRMATION: 'PAN_CONFIRMATION',
  ADDRESS_PROOF: 'ADDRESS_PROOF',
  AADHAAR_KYC: 'AADHAAR_KYC',
  BANK_DETAILS: 'BANK_DETAILS',
  BANK_VERIFICATION: 'BANK_VERIFICATION',
  CHEQUE_VERIFICATION: 'CHEQUE_VERIFICATION',
  SELFIE: 'SELFIE',
  IPV: 'IPV',
  SIGNATURE: 'SIGNATURE',
  PERSONAL_DETAILS: 'PERSONAL_DETAILS',
  DECLARATIONS: 'DECLARATIONS',
  NOMINEE: 'NOMINEE',
  SEGMENT_SELECTION: 'SEGMENT_SELECTION',
  ESIGN: 'ESIGN',
  KRA: 'KRA',
  UNDER_VERIFICATION: 'UNDER_VERIFICATION',
};
const STAGE = {
  STAGE_TYPE,
};
export default STAGE;
