import Operations from './operations';
import STATUS from '../constants/approvalStages';

export default {
  userLogout: (router) => {
    router.push('/onboarding');
  },
  sessionExpired: (error) => {
    if (window.location.pathname.includes('/onboarding')) {
      if (window.location.pathname !== '/onboarding') window.location.pathname = '/onboarding';
    }
    if (window.location.pathname.includes('/admin')) {
      if (window.location.pathname !== '/admin/login') window.location.pathname = '/admin/login';
    }
    if (window.location.pathname !== '/onboarding') {
      const systemError = Operations.axiosErrorHandler(error);
      const message = systemError.status;
      const description = systemError.message
        ? systemError.message
        : '';
      Operations.notificationError(message, description);
    }
  },
  clientStatus: (status) => {
    switch (status) {
      case STATUS.STATUS.ACCEPTED:
        return 'Accepted';
      case STATUS.STATUS.REJECTED:
        return 'Rejected';
      case STATUS.STATUS.PENDING_APPROVAL:
        return 'Pending approval';
      case STATUS.STATUS.INVITED:
        return 'Invited';
      case STATUS.STATUS.IN_PROGRESS:
        return 'In progress';
      case STATUS.STATUS.KYC_PENDING:
        return 'Kyc pending';
      case STATUS.STATUS.KYC_REJECTED:
        return 'Kyc rejected';
      case STATUS.STATUS.KYC_ACCEPTED:
        return 'Kyc accepted';
      case STATUS.STATUS.ACTIVE:
        return 'Active';
      default:
        return '-';
    }
  },
};
