<template>
  <div class="common-header-wrapper">
    <div v-if="showOnboardingHeader" class="onboarding-header-wrapper">
      <div class="onboarding-common-header-root">
        <div class="logo-logout-wrapper">
          <div class="logo-logout-container">
            <div class="castlegate-logo">
              <img src="../../../assets/KeynoteLogo.jpg" />
            </div>
            <div class="admin-client-logout-icon">
              <div v-if="clientLogin" class="admin-client-logout-button">
                <a-popover placement="bottom" title="">
                  <template #content>
                    <p
                      style="
                        font-size: 18px;
                        font-family: Pangram Regular;
                        color: #41414e;
                        padding: 5px;
                        cursor: pointer;
                      "
                      @click="onClientLogout"
                    >
                      Logout
                    </p>
                  </template>
                  <logout-outlined
                    :style="{
                      fontSize: '20px',
                      color: 'black',
                      margin: auto,
                    }"
                  />
                </a-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showAdminHeader" class="admin-header-wrapper">
      <div class="admin-dashboard-common-header-root">
        <div><img src="../../../assets/KeynoteLogo.jpg" /></div>
        <div  v-if="adminLogin"
        class="dashobard-header-user-avatar-with-id">
          <div class="admin-header-bow-login-btn-wrapper">
            <a-button @click="onCLickBowLogin">BOW Login</a-button>
          </div>
          <a-popover placement="bottom" title="">
            <template #content>
              <p
                style="
                  font-size: 18px;
                  font-family: Pangram Regular;
                  color: #41414e;
                  padding: 5px;
                  cursor: pointer;
                "
                @click="onAdminLogout"
              >
                Logout
              </p>
            </template>
            <logout-outlined
              :style="{
                fontSize: '20px',
                color: 'black',
              }"
            />
          </a-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { LogoutOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import {
  onMounted, computed, ref, watch,
} from 'vue';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import services from '../../../services/apis';
// import { message } from 'ant-design-vue';

export default {
  components: {
    LogoutOutlined,
  },
  setup() {
    const store = useStore();
    // const route = useRoute();
    const router = useRouter();
    const showOnboardingHeader = ref(false);
    const showAdminHeader = ref(false);
    const userType = ref('');
    const adminLogin = ref(false);
    const clientLogin = ref(false);

    watch(store.state, () => {
      userType.value = computed(
        () => store.getters['onboardingStore/getAppHeader'],
      ).value;
      adminLogin.value = computed(
        () => store.getters['onboardingStore/getAdminLogin'],
      ).value;
      clientLogin.value = computed(
        () => store.getters['onboardingStore/getClientLogin'],
      ).value;
      if (userType.value === 'CLIENTUSER') {
        showOnboardingHeader.value = true;
      } else {
        showOnboardingHeader.value = false;
      }

      if (userType.value === 'ADMINUSER') {
        showAdminHeader.value = true;
      } else {
        showAdminHeader.value = false;
      }
    });

    const onAdminLogout = () => {
      services
        .adminLogout()
        .then((response) => {
          if (response.status === 200) {
            store.dispatch('onboardingStore/updateAdminLogin', false);
            router.push('/admin/login');
            message.success('Logout successful', 2);
          }
        })
        .catch(() => {
        });
    };

    const onCLickBowLogin = () => {
      router.push('/admin/bow-login');
    };

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    const onClientLogout = () => {
      services
        .adminLogout()
        .then((response) => {
          if (response.status === 200) {
            store.dispatch('onboardingStore/updateClientLogin', false);
            router.push('/onboarding');
          }
        })
        .catch(() => {
        });
    };

    return {
      onAdminLogout,
      onCLickBowLogin,
      onClientLogout,
      showOnboardingHeader,
      showAdminHeader,
      adminLogin,
      clientLogin,
    };
  },
};
</script>

<style lang="scss">
@import "../styles/commonHeader.scss";
</style>
