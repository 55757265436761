import LogRocket from 'logrocket';
import createLogRocketPlugin from 'logrocket-vuex';
import { APP_ENV, LOG_ROCKET_SECRET } from '../../constants';

let lr = null;
class Logrocket {
  constructor(env = '', appId = '') {
    this.env = env;
    this.appId = appId;
    this.isEnabled = !!(this.env === 'production' && this.appId);
    this.lr = null;
    this.lrVuexPlugin = null;

    if (this.isEnabled) {
      this.lr = LogRocket;
      this.lr.init(this.appId);
      this.lrVuexPlugin = createLogRocketPlugin(this.lr);
    }
  }

  isAllowed() {
    return this.isEnabled;
  }

  logRocket() {
    return this.lr;
  }

  vuexPlugin() {
    return this.lrVuexPlugin;
  }

  setUser(userId, { mobile = '', name = '', email = '' } = {}) {
    if (this.isEnabled) {
      this.lr.identify(userId, { mobile, name, email });
    }
  }
}

export default function getLogRocket() {
  if (!lr) {
    lr = new Logrocket(APP_ENV, LOG_ROCKET_SECRET);
  }

  return lr;
}
