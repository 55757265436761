/* eslint-disable */
import router from '../router/index';
import STAGES from '../../../constants/stages';
import STATUS from '../../../constants/approvalStages'

const state = () => ({
  aadharResponse: {},
  frontAadhar: '',
  backAadhar: '',
  mobileNumber: '',
  email: '',
  clientName: '',
  routeName: '',
  verifyPhoto: '',
  verifySignature: '',
  header: '',
  currentStage: '',
  clientId: '',
  bowResponse: {},
  isAdminLogin: false,
  isClientLogin: false,
});

const mutations = {
  setUploadedAadharResponse(state, address) {
    state.aadharResponse = address;
  },
  setFrontAadhar(state, frontAadhar) {
    state.frontAadhar = frontAadhar;
  },
  setBackAadhar(state, backAadhar) {
    state.backAadhar = backAadhar;
  },
  setMobileNumber(state, mobileNumber) {
    state.mobileNumber = mobileNumber;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setClientName(state, clientName) {
    state.clientName = clientName;
  },
  setRouteName(state, routeName) {
    state.routeName = routeName;
  },
  setVerifyPhoto(state, verifyPhoto) {
    state.verifyPhoto = verifyPhoto;
  },
  setVerifySignature(state, verifySignature) {
    state.verifySignature = verifySignature;
  },
  setAppHeader(state, header) {
    state.header = header;
  },
  setCurrentStage(state, currentStage) {
    state.currentStage = currentStage;
  },
  setClientId(state, clientId) {
    state.clientId = clientId;
  },
  setBowResponse(state, bowResponse) {
    state.bowResponse = bowResponse;
  },
  setAdminLogin(state, isAdminLogin) {
    state.isAdminLogin = isAdminLogin;
  },
  setClientLogin(state, isClientLogin) {
    state.isClientLogin = isClientLogin;
  },
};

const actions = {
  updateUploadedAadhar({ commit }, address) {
    commit('setUploadedAadharResponse', address);
  },
  updateFrontAadhar({ commit }, frontAadhar) {
    commit('setFrontAadhar', frontAadhar);
  },
  updateBackAadhar({ commit }, backAadhar) {
    commit('setBackAadhar', backAadhar);
  },
  updateMobileNumber({ commit }, mobileNumber) {
    commit('setMobileNumber', mobileNumber);
  },
  updateEmail({ commit }, email) {
    commit('setEmail', email);
  },
  updateClientName({ commit }, clientName) {
    commit('setClientName', clientName);
  },
  updateRouteName({ commit }, routeName) {
    commit('setRouteName', routeName);
  },
  updateVerifyPhoto({ commit }, verifyPhoto) {
    commit('setVerifyPhoto', verifyPhoto);
  },
  updateVerifySignature({ commit }, verifySignature) {
    commit('setVerifySignature', verifySignature);
  },
  updateAppHeader({ commit }, header) {
    commit('setAppHeader', header);
  },
  updateCurrentStage({ commit }, currentStage) {
    commit('setCurrentStage', currentStage);
  },
  updateClientId({ commit }, clientId) {
    commit('setClientId', clientId);
  },
  updateBowResponse({ commit }, bowResponse) {
    commit('setBowResponse', bowResponse);
  },
  updateAdminLogin({ commit }, isAdminLogin) {
    commit('setAdminLogin', isAdminLogin);
  },
  updateClientLogin({ commit }, isClientLogin) {
    commit('setClientLogin', isClientLogin);
  },
  updateStage({ commit }, stage) {
    switch (stage) {
      // case 'ACCOUNT_VALIDATION':
      //   router.push('/mobile/validation');
      //   break;
      case STAGES.STAGE_TYPE.VALIDATE_MOBILE:
        router.push('/onboarding');
        break;
      case STAGES.STAGE_TYPE.VALIDATE_EMAIL:
        router.push('/onboarding/lead/info');
        break;
      case STAGES.STAGE_TYPE.MOBILE_ACCOUNT_VALIDATION:
        router.push('/onboarding/mobile/validation');
        break;
      case STAGES.STAGE_TYPE.EMAIL_ACCOUNT_VALIDATION:
        router.push('/onboarding/email/validation');
        break;
      case STAGES.STAGE_TYPE.PAN_VALIDATION:
        router.push('/onboarding/pan/details');
        break;
      case STAGES.STAGE_TYPE.PAN_CONFIRMATION:
        router.push('/onboarding/pan/upload');
        break;
      case STAGES.STAGE_TYPE.ADDRESS_PROOF:
        router.push('/onboarding/upload/address');
        break;
      case STAGES.STAGE_TYPE.AADHAAR_KYC:
        router.push('/onboarding/aadhaar/kyc');
        break;
      case STAGES.STAGE_TYPE.CHEQUE_VERIFICATION:
        router.push('/onboarding/upload/cheque');
        break;
      case STAGES.STAGE_TYPE.BANK_DETAILS:
        router.push('/onboarding/bank/details');
        break;
      case STAGES.STAGE_TYPE.BANK_VERIFICATION:
        router.push('/onboarding/bank/verification');
        break;
      case STAGES.STAGE_TYPE.SELFIE:
        router.push('/onboarding/upload/selfie');
        break;
      case STAGES.STAGE_TYPE.IPV:
        router.push('/onboarding/upload/ipv');
        break;
      case STAGES.STAGE_TYPE.SIGNATURE:
        router.push('/onboarding/signature/add');
        break;
      case STAGES.STAGE_TYPE.PERSONAL_DETAILS:
        router.push('/onboarding/profile/details');
        break;
      case STAGES.STAGE_TYPE.DECLARATIONS:
        router.push('/onboarding/declarations');
        break;
      case STAGES.STAGE_TYPE.NOMINEE:
        router.push('/onboarding/nominee/add');
        break;
      case STAGES.STAGE_TYPE.SEGMENT_SELECTION:
        router.push('/onboarding/activate/fno');
        break;
      case STAGES.STAGE_TYPE.ESIGN:
        router.push('/onboarding/aadhaar/esign');
        break;
      case STAGES.STAGE_TYPE.UNDER_VERIFICATION:
        router.push('/onboarding/esign-completed');
        break;
      default:
        router.push('/onboarding');
    }
  },
  updateStatus({ commit }, status) {
    switch (status) {
      case STATUS.STATUS.ACTIVE:
        router.push('/onboarding/approved');
    }
  }
};

const getters = {
  getUploadedAadhar: (state) => {
    return state.aadharResponse;
  },
  getFrontAadhar: (state) => {
    return state.frontAadhar;
  },
  getBackAadhar: (state) => {
    return state.backAadhar;
  },
  getMobileNumber: (state) => {
    return state.mobileNumber;
  },
  getEmail: (state) => {
    return state.email;
  },
  getClientName: (state) => {
    return state.clientName;
  },
  getRouteName: (state) => {
    return state.routeName;
  },
  getVerifyPhoto: (state) => {
    return state.verifyPhoto;
  },
  getVerifySignature: (state) => {
    return state.verifySignature;
  },
  getAppHeader: (state) => {
    return state.header;
  },
  getCurrentStage: (state) => {
    return state.currentStage;
  },
  getClientId: (state) => {
    return state.clientId;
  },
  getBowResponse: (state) => {
    return state.bowResponse;
  },
  getAdminLogin: (state) => {
    return state.isAdminLogin;
  },
  getClientLogin: (state) => {
    return state.isClientLogin;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
