import { createWebHistory, createRouter } from 'vue-router';

// import About from '@/views/About.vue';

const routes = [
  {
    path: '/',
    name: 'Home Page',
    component: () => import('../components/MobileSignUp.vue'),
  },
  {
    path: '/onboarding',
    name: 'Mobile Sign Up',
    component: () => import('../components/MobileSignUp.vue'),
  },
  {
    path: '/onboarding/mobile/otp',
    name: 'Mobile Otp',
    component: () => import('../components/MobileOtp.vue'),
  },
  {
    path: '/onboarding/mobile/validation',
    name: 'Mobile Account Validation',
    component: () => import('../components/AccountValidationMobile.vue'),
  },
  {
    path: '/onboarding/lead/info',
    name: 'Lead Information',
    component: () => import('../components/BasicInfo.vue'),
  },
  {
    path: '/onboarding/email/otp',
    name: 'Email Otp',
    component: () => import('../components/EmailOtp.vue'),
  },
  {
    path: '/onboarding/email/validation',
    name: 'Email Account Validation',
    component: () => import('../components/AccountValidationEmail.vue'),
  },
  {
    path: '/onboarding/pan/details',
    name: 'Pan Details',
    component: () => import('../components/PanDetails.vue'),
  },
  {
    path: '/onboarding/pan/uploaded',
    name: 'Pan Uploaded',
    component: () => import('../components/UploadPan.vue'),
  },
  {
    path: '/onboarding/pan/upload',
    name: 'Pan Upload',
    component: () => import('../components/UploadedPan.vue'),
  },
  {
    path: '/onboarding/aadhaar/kyc',
    name: 'Aadhaar KYC',
    component: () => import('../components/KycDigilocker.vue'),
  },
  {
    path: '/onboarding/upload/address',
    name: 'Upload Address',
    component: () => import('../components/UploadAddressProof.vue'),
  },
  {
    path: '/onboarding/address/verification',
    name: 'Uploaded Address Proof Verification',
    component: () => import('../components/UploadAddressProofVerification.vue'),
  },
  {
    path: '/onboarding/bank',
    name: 'Add Bank Options',
    component: () => import('../components/AddYourBank.vue'),
  },
  // {
  //   path: '/onboarding/upload/cheque',
  //   name: 'Upload Cheque',
  //   component: () => import('../components/UploadCheque.vue'),
  // },
  {
    path: '/onboarding/upload/cheque',
    name: 'Upload Cheque',
    component: () => import('../components/UploadChequeDetails.vue'),
  },
  {
    path: '/onboarding/bank/verification',
    name: 'Verifying Bank Account',
    component: () => import('../components/VerifyingBankAccount.vue'),
  },
  {
    path: '/onboarding/bank/add',
    name: 'Add Bank',
    component: () => import('../components/AddYourBankName.vue'),
  },
  {
    path: '/onboarding/bank/details',
    name: 'Add Bank Details Manually',
    component: () => import('../components/AddBankAccount.vue'),
  },
  {
    path: '/onboarding/upload/selfie',
    name: 'Upload Selfie',
    component: () => import('../components/Selfie.vue'),
  },
  {
    path: '/onboarding/photo/verification',
    name: 'Photo Verifying',
    component: () => import('../components/VerifyPhoto.vue'),
  },
  {
    path: '/onboarding/upload/ipv',
    name: 'Webcam verification (IPV)',
    component: () => import('../components/WebCamVerification.vue'),
  },
  {
    path: '/onboarding/signature/add',
    name: 'Add Signature',
    component: () => import('../components/AddSignature.vue'),
  },
  {
    path: '/onboarding/signature/verification',
    name: 'Verify Signature',
    component: () => import('../components/VerifySignature.vue'),
  },
  {
    path: '/onboarding/profile/details',
    name: 'Profile Details',
    component: () => import('../components/ProfileDetails.vue'),
  },
  {
    path: '/onboarding/declarations',
    name: 'Declarations',
    component: () => import('../components/Declarations.vue'),
  },
  {
    path: '/onboarding/nominee/add',
    name: 'Add Nominee',
    component: () => import('../components/AddNominee.vue'),
  },
  {
    path: '/onboarding/activate/fno',
    name: 'Activate Future and Options',
    component: () => import('../components/FutureOptions.vue'),
  },
  {
    path: '/onboarding/aadhaar/esign',
    name: 'Aadhar eSign',
    component: () => import('../components/AadharESign.vue'),
  },
  {
    path: '/admin/admin-dashboard',
    name: 'Admin Dashboard',
    component: () => import('../components/AdminDashboard.vue'),
  },
  {
    path: '/admin/admin-approval',
    name: 'Admin Dashboard Approval',
    component: () => import('../components/AdminDashboardApproval.vue'),
  },
  {
    path: '/admin/kyc-approval/:id',
    name: 'Admin Dashboard Kyc Approval',
    component: () => import('../components/AdminKycApproval.vue'),
  },
  {
    path: '/admin/login',
    name: 'Admin Login',
    component: () => import('../components/AdminLogin.vue'),
  },
  {
    path: '/admin/bow-login',
    name: 'Bow Login',
    component: () => import('../components/BowLogin.vue'),
  },
  {
    path: '/admin/bow-verifyOtp',
    name: 'Bow Verify Otp',
    component: () => import('../components/BowVerifyOtp.vue'),
  },
  {
    path: '/onboarding/esign-completed',
    name: 'Esign completed',
    component: () => import('../components/esignCompleted.vue'),
  },
  {
    path: '/onboarding/approved',
    name: 'Profile Verified',
    component: () => import('../components/ApprovedProfile.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
