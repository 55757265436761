/* eslint-disable */
const MARITAL_STATUS = {
  UNMARRIED: 'Unmarried',
  MARRIED: 'Married',
};
const GENDER = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
};
const ANNUAL_INCOME = {
  '01': 'BELOW 1 LAC',
  '02': '1-5 LAC',
  '03': '5-10 LAC',
  '04': '10-25 LAC',
  '05': '> 25 LAC', 
  '06': '25 LAC - 1 CR',
  '07': '>1 CR',
  10: 'UPTO RS.5,00,000',
  11: 'RS.5,00,001 TO RS.25,00,000',
  12: 'RS.5,00,00,001 AND ABOVE',
  13: 'RS.25,00,001 TO RS.1,00,00,000',
  14: 'RS.1,00,00,001 TO RS.5,00,00,000', 
  15: 'UPTO RS.50,00,000', 
  16: 'RS.50,00,001 TO RS.2,50,00,000', 
  17: 'RS.2,50,00,001 TO RS.10,00,00,000',
  18: 'RS.10,00,00,001 TO RS.50,00,00,000', 
  19: 'RS.50,00,00,001 AND ABOVE', 
};
const PROFESSIONAL = {
  GOVERNMENT_SERVICE: 'GOVERNMENT SERVICE',
  PUBLIC_SECTOR_OR_GOVERNMENT_SERVICE: 'PUBLIC SECTOR / GOVERNMENT SERVICE',
  OTHERS: 'OTHERS',
  PRIVATE_SECTOR_SERVICE: 'PRIVATE SECTOR SERVICE',
  PUBLIC_SECTOR: 'PUBLIC SECTOR',
  BUSINESS: 'BUSINESS',
  PROFESSIONAL: 'PROFESSIONAL',
  AGRICULTURIST: 'AGRICULTURIST',
  RETIRED: 'RETIRED',
  HOUSEWIFE: 'HOUSEWIFE',
  STUDENT: 'STUDENT',
  FOREX_DEALER: 'FOREX DEALER',
};
const TRADING_EXPERIENCE = {
  ZERO_TO_ONE_YEAR: '0 - 1 Year',
  ONE_TO_TWO_YEAR: '1 - 2 Years',
  TWO_TO_FOUR_YEAR: '2 - 4 Years',
  FOUR_TO_SIX_YEAR: '4 - 6 Years',
  SIX_TO_TEN_YEAR: '6 - 10 Years',
  MORE_THAN_TEN_YEAR: 'More than 10 Years',
};
const ERROR_CODE_MESSAGE = {
  401: 'Session Expired!',
  400: 'Validation Error!',
}
const ACCOUNT_TYPE = {
  SAVINGS: 'SAVINGS',
  CURRENT: 'CURRENT',
}
const TYPE = {
  MARITAL_STATUS,
  GENDER,
  ANNUAL_INCOME,
  PROFESSIONAL,
  TRADING_EXPERIENCE,
  ERROR_CODE_MESSAGE,
  ACCOUNT_TYPE,
};
export default TYPE;
