import ROUTES from '../../../constants/routes';

const ROUTER = {
  MOBILE_SIGNUP: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.MOBILE_SIGNUP,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  MOBILE_OTP: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.MOBILE_OTP,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  EMAIL_SIGNUP: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.EMAIL_SIGNUP,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  EMAIL_OTP: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.EMAIL_OTP,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  VALIDATE_ACCOUNT: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.ACCOUNT_VALIDATION,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  AUTHENTICATION_PAN: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.PAN_DETAILS,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  UPLOAD_PAN: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.PAN_UPLOAD,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  CONFIRM_UPLOAD_PAN: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.CONFIRM_PAN_DETAILS,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  UPLOAD_ADDRESS_PROOF: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.UPLOAD_ADDRESS_PROOF,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  CONFIRM_UPLOADED_ADDRESS_PROOF: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.CONFIRM_ADDRESS_PROOF,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  ADD_PERSONAL_DETAIL: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.PERSONAL_DETAILS,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  DECLARATIONS: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.DECLARATIONS,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  ADD_NOMINEE: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.ADD_NOMINEE,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  UPLOAD_DOCUMENT: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.UPLOAD_DOCUMENT,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  ACTIVATE_FNO: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.ACTIVATE_FNO,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  ADD_BANK_MANUALLY: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.ADD_BANK_MANUALLY,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  BANK_ACCOUNT_VERIFICATION_STATUS: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.BANK_ACCOUNT_VERIFICATION,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  CHEQUE_UPLOAD: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.CHEQUE_UPLOAD,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  SELFIE_VERIFICATION: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.SELFIE_VERIFICATION,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  SIGNATURE_VERIFICATION: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.SIGNATURE_VERIFICATION,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  GET_STAGES: {
    METHOD: 'GET',
    URL: ROUTES.ONBOARDING.GET_STAGES,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  GOOGLE_AUTHENTICATION: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.GOOGLE_AUTHENTICATION,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  AADHAAR_ESIGN: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.AADHAAR_ESIGN,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  FETCH_DASHBOARD_STATISTICS: {
    METHOD: 'GET',
    URL: ROUTES.ADMIN_DASHBOARD.FETCH_DASHBOARD_STATISTICS,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  FETCH_DASHBOARD_KYCS: {
    METHOD: 'GET',
    URL: ROUTES.ADMIN_DASHBOARD.FETCH_DASHBOARD_KYCS,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  ACCEPT_KYC: {
    METHOD: 'POST',
    URL: ROUTES.ADMIN_DASHBOARD.ACCEPT_KYC,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  FETCH_KYC_APPLICATION: {
    METHOD: 'GET',
    URL: ROUTES.ADMIN_DASHBOARD.FETCH_KYC_APPLICATION,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  ACCEPT_KYC_STAGE: {
    METHOD: 'POST',
    URL: ROUTES.ADMIN_DASHBOARD.ACCEPT_KYC_STAGE,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  REJECT_KYC_STAGE: {
    METHOD: 'POST',
    URL: ROUTES.ADMIN_DASHBOARD.REJECT_KYC_STAGE,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  ADMIN_LOGIN: {
    METHOD: 'POST',
    URL: ROUTES.ADMIN_DASHBOARD.ADMIN_LOGIN,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  ADMIN_LOGOUT: {
    METHOD: 'POST',
    URL: ROUTES.ADMIN_DASHBOARD.ADMIN_LOGOUT,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  BOW_USER_ACTIVATED: {
    METHOD: 'POST',
    URL: ROUTES.ADMIN_DASHBOARD.BOW_USER_ACTIVATED,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  BOW_USER_LOGIN: {
    METHOD: 'POST',
    URL: ROUTES.ADMIN_DASHBOARD.BOW_USER_LOGIN,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  BOW_VERIFY_OTP: {
    METHOD: 'POST',
    URL: ROUTES.ADMIN_DASHBOARD.BOW_VERIFY_OTP,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  BOW_RESEND_OTP: {
    METHOD: 'POST',
    URL: ROUTES.ADMIN_DASHBOARD.BOW_RESEND_OTP,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  AADHAAR_ESIGN_STATUS: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.AADHAAR_ESIGN_STATUS,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  VALIDATE_SESSION: {
    METHOD: 'GET',
    URL: ROUTES.ONBOARDING.VALIDATE_SESSION,
    HEADERS: 'example=Success',
    IS_MOCK: false,
  },
  IPV_VERIFICATION: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.IPV_VERIFICATION,
    IS_MOCK: false,
  },
  KRA_SYNC: {
    METHOD: 'POST',
    URL: ROUTES.ADMIN_DASHBOARD.KRA_SYNC,
    IS_MOCK: false,
  },
  CHEQUE_CONFIRM: {
    METHOD: 'POST',
    URL: ROUTES.ONBOARDING.CHEQUE_CONFIRM,
    IS_MOCK: false,
  },
  CLOSE_ACCOUNT: {
    METHOD: 'POST',
    URL: ROUTES.ADMIN_DASHBOARD.CLOSE_ACCOUNT,
    IS_MOCK: false,
  },
};

export default ROUTER;
