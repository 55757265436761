const APPROVAL_STAGE_TYPE = {
  CLIENT_DETAILS: 'CLIENT_DETAILS',
  REGULATORY_DISCLOSURE: 'REGULATORY_DISCLOSURE',
  CORRESPONDENCE: 'CORRESPONDENCE',
  PERMANENT: 'PERMANENT',
  PRIMARY_BANK_DETAILS: 'PRIMARY_BANK_DETAILS',
  NOMINEE_DETAILS: 'NOMINEE_DETAILS',
  ADD_CLIENT_CODE: 'ADD_CLIENT_CODE',
  TRADING_PREFERENCE: 'TRADING_PREFERENCE',
  DEMAT_DETAILS: 'DEMAT_DETAILS',
  BROKERAGE_DETAILS: 'BROKERAGE_DETAILS',
  ADDITIONAL_BANK_DETAILS: 'ADDITIONAL_BANK_DETAILS',
  GUARDIAN_DETAILS: 'DEMAT_DETAILS',
};
const STATUS = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  INVITED: 'INVITED',
  IN_PROGRESS: 'IN_PROGRESS',
  KYC_PENDING: 'KYC_PENDING',
  KYC_REJECTED: 'KYC_REJECTED',
  KYC_ACCEPTED: 'KYC_ACCEPTED',
  ACTIVE: 'ACTIVE',
};
const APPROVAL_STAGE = {
  APPROVAL_STAGE_TYPE,
  STATUS,
};
export default APPROVAL_STAGE;
